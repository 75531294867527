import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  VerifyButton,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import CustomLinksMenu from './CustomLinksMenu/CustomLinksMenu';

import css from './TopbarDesktop.module.css';
import { loadStripe } from '@stripe/stripe-js';
import ContinueIcon from '../../Topbar/images/continue-icon.svg';
import { getPurchasedSubscriptionDetails, getSubscriptionDetails, getSubscriptionPermissions, getUserSubscription, subscriptionTypes, UIControls } from '../../../../util/subscriptionsHelpers';
import { getProfileListingId, getIsCombinedUserType, getIsHouseOwner, getIsHouseSitter, getIsStripeVerified, getIsVerifilePoliceCheckingVerified, getProfileListingTitle, getUserType, getUserTypeForSubscription, userTypes } from '../../../../util/userTypes';
import VerifiedImage from '../../../../assets/new-verified-logo.jpeg';
import { LISTING_PAGE_PARAM_TYPE_EDIT, LISTING_PAGE_PARAM_TYPE_NEW } from '../../../../util/urlHelpers';
import { kindTypes } from '../../../../util/listingHelpers';
import { HOUSE_SITTER_DETAILS } from '../../../EditListingPage/EditListingWizard/EditListingWizardTab';
import { post } from '../../../../util/api';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});


const SignupLink = () => {
  return (
    <NamedLink name="SignupPage" className={css.joinNow}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.joinNow" />
        <img src={ContinueIcon} className={css.icon} />
        {/* <FormattedMessage id="TopbarDesktop.signup" /> */}
      </span>
    </NamedLink>
  );
};

const LoginLink = () => {
  return (
    <NamedLink name="LoginPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
};

const HouseSitterLink = () => {
  return (
    <NamedLink name="SearchHouseSitterPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.houseSitterLink" />
      </span>
    </NamedLink>
  );
};

const HouseSitLink = () => {
  return (
    <NamedLink name="SearchHouseSitPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.houseSitLink" />
      </span>
    </NamedLink>
  );
};

const NewListingLink = () => {
  return (
    <NamedLink name="NewHouseSitListingPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.addListingLink" />
      </span>
    </NamedLink>
  );
};

const NewHouseSitterListingPageLink = () => {
  return (
    <NamedLink name="NewHouseSitterListingPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.addProfileLink" />
      </span>
    </NamedLink>
  );
};

const BlogLink = () => {
  return (
    <NamedLink name="SearchHouseSitPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.blogLink" />
      </span>
    </NamedLink>
  );
};

const MemberBenefitsMenu = ({ subscriptionType, userType }) => {
  if (!subscriptionType) {
    return (
      <NamedLink name="MembershipPlansPage" className={css.topbarLink}>
        <span className={css.topbarLinkLabel}>
          <FormattedMessage id="TopbarDesktop.memberBenefitsLink" />
        </span>
      </NamedLink>
    );
  };

  if (subscriptionType === subscriptionTypes.value) {
    return (
      <Menu>
        <MenuLabel className={css.topbarLink}>
          <span className={css.topbarLinkLabel}>
            <FormattedMessage id="TopbarDesktop.memberBenefitsLink" />
          </span>
        </MenuLabel>

        <MenuContent className={css.profileMenuContent} style={{ paddingBottom: 20 }}>
          <MenuItem key="Discounts">
            <span className={css.menuLink}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="MemberBenefitsMenu.discounts" />
            </span>
          </MenuItem>
          <MenuItem key="FreeOnsiteCals">
            <span className={css.menuLink}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="MemberBenefitsMenu.freeOnsiteCals" />
            </span>
          </MenuItem>
        </MenuContent>
      </Menu>
    )
  }

  if (subscriptionType === subscriptionTypes.choice || subscriptionType === subscriptionTypes.premium) {
    return (
      <Menu>
        <MenuLabel className={css.topbarLink}>
          <span className={css.topbarLinkLabel}>
            <FormattedMessage id="TopbarDesktop.memberBenefitsLink" />
          </span>
        </MenuLabel>

        <MenuContent className={css.profileMenuContent} style={{ paddingBottom: 20 }}>
          <MenuItem key="VetHotline">
            <div className={css.tooltipContainer}>
              <a
                href="tel:+4403333325277"
                style={{ textDecoration: "none" }}
              >
                <span className={`${css.menuLink} ${css.highlight}`}>
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id="MemberBenefitsMenu.vetHotline" />
                </span>
              </a>
              <div className={css.tooltipText}>
                Tel +44( 0) 333 332 5277
              </div>
            </div>
          </MenuItem>
          <MenuItem key="FreenNsiteCalls">
            <span className={css.menuLink}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="MemberBenefitsMenu.freeOnsiteCals" />
            </span>
          </MenuItem>
          <MenuItem key="Discounts">
            <span className={css.menuLink}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="MemberBenefitsMenu.discounts" />
            </span>
          </MenuItem>
          <MenuItem key="AccountSupport">
            <span className={css.menuLink}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="MemberBenefitsMenu.accountSupport" />
            </span>
          </MenuItem>
          <MenuItem key="HowToBoost">
            {subscriptionType === subscriptionTypes.premium &&
              <span className={`${css.menuLink} ${css.highlight}`}>
                <span className={css.menuItemBorder} />
                <FormattedMessage id={`MemberBenefitsMenu.howToBoost.${userType}`} />
              </span>
            }
          </MenuItem>
        </MenuContent>
      </Menu >
    )
  }
  return null;
};

const InboxLink = ({ notificationCount, isHouseSitter }) => {
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot}>{notificationCount}</div> : null;
  return (
    <NamedLink
      className={css.topbarLink}
      name="InboxPage"
      params={{ tab: isHouseSitter ? 'orders' : 'sales' }}
    >
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  );
};

const SubscriptionLink = ({ subscription, userType, isCombinedUserType }) => {
  return (
    <NamedLink
      className={css.topbarLink}
      name="SubscriptionsPage"
    >
      <span className={css.subscriptionLinkLabel}>
        {isCombinedUserType ?
          <>
            <FormattedMessage id={"UserType." + userTypes.homeownerAndHousesitter} />
            {' - '}
            <FormattedMessage id={"UserType." + userType} />
          </>
          :
          <FormattedMessage id={"UserType." + userType} />
        }
        {' - '}
        <FormattedMessage id={"Subscription." + subscription} />
      </span>
    </NamedLink>
  );
};

const SwitchToLink = ({ userType, onUpdateProfile }) => {
  const newUserType = userType === userTypes.homeowner ? userTypes.housesitter : userTypes.homeowner
  const label = newUserType === userTypes.homeowner ?
    <FormattedMessage id="TopbarDesktop.switchToHomeOwner" />
    :
    <FormattedMessage id="TopbarDesktop.switchToHouseSitter" />;

  return (
    <div
      className={css.topbarLink}
      onClick={() => {
        onUpdateProfile({
          publicData: {
            userType: newUserType
          }
        })
          .then(r => {
            window.location.href = '/';
          })
      }}>
      <span className={css.switchLinkLabel}>
        {label}
      </span>
    </div>
  );
};

const HowWeWorkMenu = () => {
  return (
    <Menu>
      <MenuLabel className={css.topbarLink}>
        <span className={css.topbarLinkLabel}>
          <FormattedMessage id="TopbarDesktop.howWeWorkLink" />
        </span>
      </MenuLabel>

      <MenuContent className={css.profileMenuContent} style={{ paddingBottom: 20 }}>
        <MenuItem key="WhatIsHouseSittingPage">
          <NamedLink
            className={css.menuLink}
            name="WhatIsHouseSittingPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.whatIsHouseSitting" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="BenefitsHomeownersPage">
          <NamedLink
            className={css.menuLink}
            name="BenefitsHomeownersPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.benefitsForHomeOwners" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="BenefitsHouseSittersPage">
          <NamedLink
            className={css.menuLink}
            name="BenefitsHouseSittersPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.benefitsForHouseSitters" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="HouseSittingUK">
          <NamedLink
            className={css.menuLink}
            name="HouseSittingUK"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.houseSittingUK" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="HouseSittingAbroadPage">
          <NamedLink
            className={css.menuLink}
            name="HouseSittingAbroadPage"
            params={{ location: 'default' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.houseSittingAbroad" />
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  )
}

const HouseSittingAbroad = () => {
  return (
    <Menu>
      <MenuLabel className={css.topbarLink}>
        <span className={css.topbarLinkLabel}>
          <FormattedMessage id="TopbarDesktop.houseSittingAbroad" />
        </span>
      </MenuLabel>

      <MenuContent className={css.profileMenuContent} style={{ paddingBottom: 20 }}>
        <MenuItem key="HouseSittingAbroadAustraliaPage">
          <NamedLink
            className={css.menuLink}
            name="HouseSittingAbroadPage"
            params={{ location: 'australia' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.housesittingAustralia" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="HouseSittingAbroadCanadaPage">
          <NamedLink
            className={css.menuLink}
            name="HouseSittingAbroadPage"
            params={{ location: 'canada' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.housesittingCanada" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="HouseSittingAbroadEuropePage">
          <NamedLink
            className={css.menuLink}
            name="HouseSittingAbroadPage"
            params={{ location: 'europe' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.housesittingEurope" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="HouseSittingAbroadUSAPage">
          <NamedLink
            className={css.menuLink}
            name="HouseSittingAbroadPage"
            params={{ location: 'usa' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.housesittingUSA" />
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  )
}

const PetsittingServices = () => {
  return (
    <Menu>
      <MenuLabel className={css.topbarLink}>
        <span className={css.topbarLinkLabel}>
          <FormattedMessage id="TopbarDesktop.petsittingServices" />
        </span>
      </MenuLabel>

      <MenuContent className={css.profileMenuContent} style={{ paddingBottom: 20 }}>
        <MenuItem key="PetSittingServicesDogPage">
          <NamedLink
            className={css.menuLink}
            name="PetSittingServicesPage"
            params={{ serviceType: 'dog-sitting' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.dogSitting" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="PetSittingServicesCatPage">
          <NamedLink
            className={css.menuLink}
            name="PetSittingServicesPage"
            params={{ serviceType: 'cat-sitting' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.catSitting" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="PetSittingServicesRabbitPage">
          <NamedLink
            className={css.menuLink}
            name="PetSittingServicesPage"
            params={{ serviceType: 'rabbit-sitting' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.rabbitSitting" />
          </NamedLink>
        </MenuItem>
        {/* <MenuItem key="PetSittingServicesGuineaPigPage">
          <NamedLink
            className={css.menuLink}
            name="PetSittingServicesPage"
            params={{ serviceType: 'guinea-pig-sitting' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.guineaPigSitting" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="PetSittingServicesBirdPage">
          <NamedLink
            className={css.menuLink}
            name="PetSittingServicesPage"
            params={{ serviceType: 'bird-sitting' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.birdSitting" />
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  )
}

const ProfileMenu = ({ currentPage, currentUser, isHouseSitter, onLogout }) => {
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  if (isHouseSitter) {
    const profileListingId = getProfileListingId(currentUser);
    const profileListingTitle = getProfileListingTitle(currentUser);

    return (
      <Menu>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          {/* <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </MenuItem> */}
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(css.menuLink, currentPageClass('EditListingPage'))}
              name={profileListingId ? "EditListingPage" : "NewHouseSitterListingPage"}
              params={profileListingId ? { id: profileListingId, slug: profileListingTitle || "draft", type: LISTING_PAGE_PARAM_TYPE_EDIT, tab: HOUSE_SITTER_DETAILS, kind: kindTypes.houseSitter } : {}}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
    );
  } else {
    return (
      <Menu>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
    );
  }
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const TopbarDesktop = props => {
  const {
    className,
    config,
    customLinks,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    onUpdateProfile,
  } = props;

  const [mounted, setMounted] = useState(false);

  const fetchUsersSubscriptions = async () => {
    try {
      const response = await sdk.currentUser.show();
      const user = response?.data.data;

      const stripeCustomerId = user.attributes.profile.privateData.stripeCustomerId;

      if (stripeCustomerId) {
        const requestOptions = {
          stripeCustomerId: stripeCustomerId,
        };

        const { subscriptions } = await post('/api/subscriptions-list', requestOptions);

        const fetchedSubscriptions = subscriptions.data.map(s => {
          return getPurchasedSubscriptionDetails(s);
        })

        // Extract the most recent subscription (assuming subscriptions have a date or endDate field)
        const latestActiveSubscription = fetchedSubscriptions
          .filter(subscription => subscription.status === 'active')  // Filter for active subscriptions
          .sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime())  // Sort by endDate
        [0];

        await onUpdateProfile({
          privateData: {
            subscriptions: [
              latestActiveSubscription
              // ...fetchedSubscriptions,
            ],
          }
        });
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchUsersSubscriptions();
    }
    setMounted(true);
  }, []);

  const marketplaceName = config.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const giveSpaceForSearch = customLinks == null || customLinks?.length === 0;
  const classes = classNames(rootClassName || css.root, className);

  const userType = getUserType(currentUser);

  const subscriptionType = getUserSubscription(currentUser);
  const isCombinedUserType = getIsCombinedUserType(currentUser);
  const isHouseSitter = getIsHouseSitter(currentUser);

  const subscriptionLinkMaybe = authenticatedOnClientSide && subscriptionType ? (
    <SubscriptionLink
      subscription={subscriptionType}
      userType={userType}
      isCombinedUserType={isCombinedUserType}
    />
  ) : null;

  const switchLinkMaybe = authenticatedOnClientSide && subscriptionType && isCombinedUserType ? (
    <SwitchToLink
      userType={userType}
      onUpdateProfile={onUpdateProfile}
    />
  ) : null;

  const inboxLinkMaybe = authenticatedOnClientSide ? (
    <InboxLink
      notificationCount={notificationCount}
      currentUserHasListings={currentUserHasListings}
      isHouseSitter={isHouseSitter}
    />
  ) : null;

  const profileMenuMaybe = authenticatedOnClientSide ? (
    <ProfileMenu currentPage={currentPage} currentUser={currentUser} isHouseSitter={isHouseSitter} onLogout={onLogout} />
  ) : null;

  const signupLinkMaybe = isAuthenticatedOrJustHydrated ? null : <SignupLink />;
  const loginLinkMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLink />;

  const newListingLinkMaybe = isAuthenticatedOrJustHydrated && getSubscriptionPermissions(userType, subscriptionType, UIControls.addListingButton) ? <NewListingLink /> : null;
  const newHouseSitterProfileLinkMaybe = isAuthenticatedOrJustHydrated && getSubscriptionPermissions(userType, subscriptionType, UIControls.addHouseSitterProfileButton) && !getProfileListingId(currentUser) ? <NewHouseSitterListingPageLink /> : null;

  // Further need to check user type te determine apropriate link to be displayed after login
  const houseSitterLinkMaybe = (!isAuthenticated || (isAuthenticatedOrJustHydrated && (userType === userTypes.homeowner || userType === userTypes.homeownerAndHousesitter))) && <HouseSitterLink />;
  const houseSitLinkMaybe = (!isAuthenticated || (isAuthenticatedOrJustHydrated && (userType === userTypes.housesitter || userType === userTypes.homeownerAndHousesitter))) && <HouseSitLink />; // && getSubscriptionPermissions(userType, subscriptionType, UIControls.findAHouseSitButton)

  const memberBenefitsMenuMaybe = isAuthenticatedOrJustHydrated &&
    <MemberBenefitsMenu
      subscriptionType={subscriptionType}
      userType={userType}
    />;

  const isStripeVerified = getIsStripeVerified(currentUser);
  const hasSubscription = getUserSubscription(currentUser);

  const verifyButtonMaybe = isAuthenticatedOrJustHydrated && getSubscriptionPermissions(userType, subscriptionType, UIControls.verifyButton) && !isStripeVerified &&
    <VerifyButton
      isAuthenticated={isAuthenticated}
      stripePromise={stripePromise}
      currentUser={currentUser}
      key={currentUser ? currentUser.id : 'logged-out'}
    // id={id}
    />

  const verifiedStatusMaybe = isAuthenticatedOrJustHydrated && getSubscriptionPermissions(userType, subscriptionType, UIControls.verifyButton)
    && isStripeVerified &&
    <img
      className={css.veriffPicMobile}
      src={VerifiedImage}
      alt="verified"
      width={80}
      height={35}
    />

  const IDChecksMenu = userType === userTypes.homeowner ? (
    <Menu>
      <MenuLabel className={css.topbarLink}>
        <span className={css.topbarLinkLabel}>
          <FormattedMessage id="TopbarDesktop.idChecks" />
        </span>
      </MenuLabel>

      <MenuContent className={css.profileMenuContent} style={{ paddingBottom: 20 }}>

        <MenuItem key="Verify">
          <MenuItem key="Verify">
            <div>
              {verifyButtonMaybe}
            </div>
          </MenuItem>
        </MenuItem>
      </MenuContent>
    </Menu>
  )
    : (
      <Menu>
        <MenuLabel className={css.topbarLink}>
          <span className={css.topbarLinkLabel}>
            <FormattedMessage id="TopbarDesktop.idChecks" />
          </span>
        </MenuLabel>

        <MenuContent className={css.profileMenuContent} style={{ paddingBottom: 20 }}>

          <MenuItem key="Verify">
            <MenuItem key="Verify">
              {verifyButtonMaybe}
            </MenuItem>

          </MenuItem>

          <MenuItem key="VerifilePoliceCheckingPage">
            <MenuItem key="VerifilePoliceCheckingPage">
              <NamedLink
                className={css.menuLink}
                name="VerifilePoliceCheckingPage"
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.verifilePoliceCheckingLink" />
              </NamedLink>
            </MenuItem>

          </MenuItem>

        </MenuContent>
      </Menu>
    )

  const IDChecksMenuMaybe = subscriptionType && isAuthenticated ? IDChecksMenu : null;

  // const isVerifilePoliceCheckingVerified = getIsVerifilePoliceCheckingVerified(currentUser);

  // const verifilePoliceCheckingStatusButtonMaybe = isAuthenticatedOrJustHydrated && getSubscriptionPermissions(userType, subscriptionType, UIControls.verifilePoliceCheckingButton) && !isVerifilePoliceCheckingVerified &&
  //   <NamedLink name="VerifilePoliceCheckingPage" className={css.topbarLink}>
  //     <span className={css.topbarLinkLabel}>
  //       <FormattedMessage id="TopbarDesktop.verifilePoliceCheckingLink" />
  //     </span>
  //   </NamedLink>

  // const verifilePoliceCheckingStatusMaybe = isAuthenticatedOrJustHydrated && getSubscriptionPermissions(userType, subscriptionType, UIControls.verifyButton)
  //   && isVerifilePoliceCheckingVerified &&
  //   <p>
  //     Checked
  //   </p>

  return (
    <nav className={classes}>
      <div className={css.leftSideNav}>
        <LinkedLogo
          className={css.logoLink}
          logoImageClassName={css.logoImage}
          layout="desktop"
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
          linkToExternalSite={config?.topbar?.logoLink}
        />

        {houseSitterLinkMaybe}
        {houseSitLinkMaybe}
      </div>
      {/* <TopbarSearchForm
        className={classNames(css.searchLink, { [css.takeAvailableSpace]: giveSpaceForSearch })}
        desktopInputRoot={css.topbarSearchWithLeftPadding}
        onSubmit={onSearchSubmit}
        initialValues={initialSearchFormValues}
        appConfig={config}
      /> */}

      {/* <CustomLinksMenu
        currentPage={currentPage}
        customLinks={customLinks}
        intl={intl}
        hasClientSideContentReady={authenticatedOnClientSide || !isAuthenticatedOrJustHydrated}
      /> */}

      <div className={css.rightSideNav}>
        {/* <BlogLink />//TO be added when blogs are online */}
        {switchLinkMaybe}
        {newListingLinkMaybe}
        {newHouseSitterProfileLinkMaybe}
        {inboxLinkMaybe}
        {/* {verifyButtonMaybe} */}
        {/* {verifiedStatusMaybe} */}
        {/* {verifilePoliceCheckingStatusButtonMaybe} */}
        {/* {verifilePoliceCheckingStatusMaybe} */}
        {/* {subscriptionLinkMaybe} */}
        {memberBenefitsMenuMaybe}
        <HowWeWorkMenu />
        <HouseSittingAbroad />
        <PetsittingServices />
        {IDChecksMenuMaybe}
        {profileMenuMaybe}
        {loginLinkMaybe}
        {signupLinkMaybe}
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  config: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  config: object,
};

export default TopbarDesktop;
