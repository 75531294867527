/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { ensureCurrentUser } from '../../../../util/data';

import {
  AvatarLarge,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
} from '../../../../components';

import css from './TopbarMobileMenu.module.css';

import { getIsCombinedUserType, getIsHouseSitter, getProfileListingId, getProfileListingTitle, getUserType, userTypes } from '../../../../util/userTypes';
import { kindTypes } from '../../../../util/listingHelpers';
import { HOUSE_SITTER_DETAILS } from '../../../EditListingPage/EditListingWizard/EditListingWizardTab';
import { post } from '../../../../util/api';
import { getPurchasedSubscriptionDetails, getUserSubscription, subscriptionTypes } from '../../../../util/subscriptionsHelpers';
import { LISTING_PAGE_PARAM_TYPE_EDIT } from '../../../../util/urlHelpers';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const SwitchToLink = ({ userType, onUpdateProfile }) => {
  const newUserType = userType === userTypes.homeowner ? userTypes.housesitter : userTypes.homeowner
  const label = newUserType === userTypes.homeowner ?
    <FormattedMessage id="TopbarDesktop.switchToHomeOwner" />
    :
    <FormattedMessage id="TopbarDesktop.switchToHouseSitter" />;

  return (
    <div
      className={css.topbarLink}
      onClick={() => {
        onUpdateProfile({
          publicData: {
            userType: newUserType
          }
        })
          .then(r => {
            window.location.href = '/';
          })
      }}>
      <span className={css.switchLinkLabel}>
        {label}
      </span>
    </div>
  );
};

const ProfileSettingsLink = ({ isHouseSitter, currentUser, currentPageClass }) => {
  if (isHouseSitter) {
    const profileListingId = getProfileListingId(currentUser);
    const profileListingTitle = getProfileListingTitle(currentUser);
    const hasProfile = Boolean(profileListingId);

    return (
      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('EditListingPage'))}
        name={hasProfile ? "EditListingPage" : "NewHouseSitterListingPage"}
        params={
          hasProfile
            ? {
              id: profileListingId,
              slug: profileListingTitle || "draft",
              type: LISTING_PAGE_PARAM_TYPE_EDIT,
              tab: HOUSE_SITTER_DETAILS,
              kind: kindTypes.houseSitter,
            }
            : {}
        }
      >
        <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
      </NamedLink>
    );
  }

  return (
    <NamedLink
      className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
      name="ProfileSettingsPage"
    >
      <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
    </NamedLink>
  );
};

const ManageListingsMaybe = ({ isHouseSitter, currentPageClass }) => {
  if (isHouseSitter) return null;
  return (
    <NamedLink
      className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
      name="ManageListingsPage"
    >
      <FormattedMessage id="TopbarDesktop.yourListingsLink" />
    </NamedLink>
  );
};

const MemberBenefitsMenu = ({ subscriptionType, userType, currentPageClass }) => {
  if (!subscriptionType) {
    return (
      <NamedLink
        className={classNames(css.navigationLink, currentPageClass('MembershipPlansPage'))}
        name="MembershipPlansPage"
      >
        <FormattedMessage id="TopbarDesktop.memberBenefitsLink" />
      </NamedLink>
    );
  };

  if (subscriptionType === subscriptionTypes.value) {
    return (
      <Menu>
        <MenuLabel className={css.navigationLink}>
          <FormattedMessage id="TopbarDesktop.memberBenefitsLink" />
        </MenuLabel>

        <MenuContent className={css.profileMenuContent} style={{ paddingBottom: 20 }}>
          <MenuItem key="Discounts">
            <span className={css.menuLink}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="MemberBenefitsMenu.discounts" />
            </span>
          </MenuItem>
          <MenuItem key="FreeOnsiteCals">
            <span className={css.menuLink}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="MemberBenefitsMenu.freeOnsiteCals" />
            </span>
          </MenuItem>
        </MenuContent>
      </Menu>
    )
  }

  if (subscriptionType === subscriptionTypes.choice || subscriptionType === subscriptionTypes.premium) {
    return (
      <Menu>
        <MenuLabel className={css.navigationLink}>
          <FormattedMessage id="TopbarDesktop.memberBenefitsLink" />
        </MenuLabel>

        <MenuContent className={css.profileMenuContent} style={{ paddingBottom: 20 }}>
          <MenuItem key="VetHotline">
            <div className={css.tooltipContainer}>
              <a
                href="tel:+4403333325277"
                style={{ textDecoration: "none" }}
              >
                <span className={`${css.menuLink} ${css.highlight}`}>
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id="MemberBenefitsMenu.vetHotline" />
                </span>
              </a>
              <div className={css.tooltipText}>
                Tel +44( 0) 333 332 5277
              </div>
            </div>
          </MenuItem>
          <MenuItem key="FreenNsiteCalls">
            <span className={css.menuLink}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="MemberBenefitsMenu.freeOnsiteCals" />
            </span>
          </MenuItem>
          <MenuItem key="Discounts">
            <span className={css.menuLink}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="MemberBenefitsMenu.discounts" />
            </span>
          </MenuItem>
          <MenuItem key="AccountSupport">
            <span className={css.menuLink}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="MemberBenefitsMenu.accountSupport" />
            </span>
          </MenuItem>
          <MenuItem key="HowToBoost">
            {subscriptionType === subscriptionTypes.premium &&
              <span className={`${css.menuLink} ${css.highlight}`}>
                <span className={css.menuItemBorder} />
                <FormattedMessage id={`MemberBenefitsMenu.howToBoost.${userType}`} />
              </span>
            }
          </MenuItem>
        </MenuContent>
      </Menu >
    )
  }
  return null;
};

const NewListingLink = ({ isHouseSitter, currentPageClass }) => {
  if (isHouseSitter) return null;
  return (
    <NamedLink
      className={classNames(css.navigationLink, currentPageClass('EditListingPage'))}
      name="NewHouseSitListingPage"
    >
      <FormattedMessage id="TopbarDesktop.addListingLink" />
    </NamedLink >
  );
};

const HowWeWorkMenu = () => {
  return (
    <Menu>
      <MenuLabel className={css.navigationLink}>
        <FormattedMessage id="TopbarDesktop.howWeWorkLink" />
      </MenuLabel>

      <MenuContent className={css.profileMenuContent} style={{ paddingBottom: 20 }}>
        <MenuItem key="WhatIsHouseSittingPage">
          <NamedLink
            className={css.menuLink}
            name="WhatIsHouseSittingPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.whatIsHouseSitting" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="BenefitsHomeownersPage">
          <NamedLink
            className={css.menuLink}
            name="BenefitsHomeownersPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.benefitsForHomeOwners" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="BenefitsHouseSittersPage">
          <NamedLink
            className={css.menuLink}
            name="BenefitsHouseSittersPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.benefitsForHouseSitters" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="HouseSittingUK">
          <NamedLink
            className={css.menuLink}
            name="HouseSittingUK"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.houseSittingUK" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="HouseSittingAbroadPage">
          <NamedLink
            className={css.menuLink}
            name="HouseSittingAbroadPage"
            params={{ location: 'default' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.houseSittingAbroad" />
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  )
}

const HouseSittingAbroad = () => {
  return (
    <Menu>
      <MenuLabel className={css.navigationLink}>
        <FormattedMessage id="TopbarDesktop.houseSittingAbroad" />
      </MenuLabel>

      <MenuContent className={css.profileMenuContent} style={{ paddingBottom: 20 }}>
        <MenuItem key="HouseSittingAbroadAustraliaPage">
          <NamedLink
            className={css.menuLink}
            name="HouseSittingAbroadPage"
            params={{ location: 'australia' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.housesittingAustralia" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="HouseSittingAbroadCanadaPage">
          <NamedLink
            className={css.menuLink}
            name="HouseSittingAbroadPage"
            params={{ location: 'canada' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.housesittingCanada" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="HouseSittingAbroadEuropePage">
          <NamedLink
            className={css.menuLink}
            name="HouseSittingAbroadPage"
            params={{ location: 'europe' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.housesittingEurope" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="HouseSittingAbroadUSAPage">
          <NamedLink
            className={css.menuLink}
            name="HouseSittingAbroadPage"
            params={{ location: 'usa' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.housesittingUSA" />
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  )
}

const PetsittingServices = () => {
  return (
    <Menu>
      <MenuLabel className={css.navigationLink}>
        <FormattedMessage id="TopbarDesktop.petsittingServices" />
      </MenuLabel>

      <MenuContent className={css.profileMenuContent} style={{ paddingBottom: 20 }}>
        <MenuItem key="PetSittingServicesDogPage">
          <NamedLink
            className={css.menuLink}
            name="PetSittingServicesPage"
            params={{ serviceType: 'dog-sitting' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.dogSitting" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="PetSittingServicesCatPage">
          <NamedLink
            className={css.menuLink}
            name="PetSittingServicesPage"
            params={{ serviceType: 'cat-sitting' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.catSitting" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="PetSittingServicesRabbitPage">
          <NamedLink
            className={css.menuLink}
            name="PetSittingServicesPage"
            params={{ serviceType: 'rabbit-sitting' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.rabbitSitting" />
          </NamedLink>
        </MenuItem>
        {/* <MenuItem key="PetSittingServicesGuineaPigPage">
          <NamedLink
            className={css.menuLink}
            name="PetSittingServicesPage"
            params={{ serviceType: 'guinea-pig-sitting' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.guineaPigSitting" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="PetSittingServicesBirdPage">
          <NamedLink
            className={css.menuLink}
            name="PetSittingServicesPage"
            params={{ serviceType: 'bird-sitting' }}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.birdSitting" />
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  )
}

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUser,
    notificationCount,
    onLogout,
    onUpdateProfile,
  } = props;

  const [mounted, setMounted] = useState(false);

  const fetchUsersSubscriptions = async () => {
    try {
      const response = await sdk.currentUser.show();
      const user = response?.data.data;

      const stripeCustomerId = user.attributes.profile.privateData.stripeCustomerId;

      if (stripeCustomerId) {
        const requestOptions = {
          stripeCustomerId: stripeCustomerId,
        };

        const { subscriptions } = await post('/api/subscriptions-list', requestOptions);

        const fetchedSubscriptions = subscriptions.data.map(s => {
          return getPurchasedSubscriptionDetails(s);
        })

        // Extract the most recent subscription (assuming subscriptions have a date or endDate field)
        const latestActiveSubscription = fetchedSubscriptions
          .filter(subscription => subscription.status === 'active')  // Filter for active subscriptions
          .sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime())  // Sort by endDate
        [0];

        await onUpdateProfile({
          privateData: {
            subscriptions: [
              latestActiveSubscription
              // ...fetchedSubscriptions,
            ],
          }
        });
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchUsersSubscriptions();
    }
    setMounted(true);
  }, []);

  const user = ensureCurrentUser(currentUser);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const subscriptionType = getUserSubscription(currentUser);
  const isCombinedUserType = getIsCombinedUserType(currentUser);
  const userType = getUserType(currentUser);
  const isHouseSitter = getIsHouseSitter(currentUser);

  const switchLinkMaybe = authenticatedOnClientSide && subscriptionType && isCombinedUserType ? (
    <SwitchToLink
      userType={userType}
      onUpdateProfile={onUpdateProfile}
    />
  ) : null;


  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );

    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
          <HowWeWorkMenu />
          <HouseSittingAbroad />
          <PetsittingServices />

          <div className={css.spacer} />
        </div>
        <div className={css.footer}>
          <NamedLink name="SearchHouseSitPage" className={css.createNewListingLink}>
            <FormattedMessage id="TopbarDesktop.houseSitLink" />
          </NamedLink>

          <NamedLink name="SearchHouseSitterPage" className={css.createNewListingLink}>
            <FormattedMessage id="TopbarDesktop.houseSitterLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    const isInboxPage = currentPage?.indexOf('InboxPage') === 0 && page?.indexOf('InboxPage') === 0;
    return currentPage === page || isAccountSettingsPage || isInboxPage ? css.currentPage : null;
  };
  const inboxTab = isHouseSitter ? 'orders' : 'sales';

  return (
    <div className={css.root}>
      <div className={css.profileContainer}>
        <AvatarLarge className={css.avatar} user={currentUser} />
        <div className={css.profileActions}>
          <span className={css.greeting}>
            <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
          </span>
          {switchLinkMaybe}
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <FormattedMessage id="TopbarMobileMenu.logoutLink" />
          </InlineTextButton>
        </div>
      </div>

      <div className={css.content}>
        <div className={css.accountLinksWrapper}>
          <NewListingLink
            isHouseSitter={isHouseSitter}
            currentPageClass={currentPageClass}
          />
          <NamedLink
            className={classNames(css.inbox, currentPageClass(`InboxPage:${inboxTab}`))}
            name="InboxPage"
            params={{ tab: inboxTab }}
          >
            <FormattedMessage id="TopbarMobileMenu.inboxLink" />
            {notificationCountBadge}
          </NamedLink>
          <MemberBenefitsMenu
            subscriptionType={subscriptionType}
            userType={userType}
            currentPageClass={currentPageClass}
          />
          <div className={css.spacer} />
          <ManageListingsMaybe
            isHouseSitter={isHouseSitter}
            currentPageClass={currentPageClass}
          />
          <ProfileSettingsLink
            isHouseSitter={isHouseSitter}
            currentUser={currentUser}
            currentPageClass={currentPageClass}
          />
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
          </NamedLink>
          <div className={css.spacer} />
          <HowWeWorkMenu />
          <HouseSittingAbroad />
          <PetsittingServices />
        </div>
        <div className={css.spacer} />
      </div>
      <div className={css.footer}>
        {isHouseSitter ?
          <NamedLink name="SearchHouseSitPage" className={css.createNewListingLink}>
            <FormattedMessage id="TopbarDesktop.houseSitLink" />
          </NamedLink>
          :
          <NamedLink name="SearchHouseSitterPage" className={css.createNewListingLink}>
            <FormattedMessage id="TopbarDesktop.houseSitterLink" />
          </NamedLink>
        }
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
