import React from "react";
import { getUpcomingMeeting } from "../../../../util/userHelpers";

import css from './MeetingReminder.module.css';
import { FormattedMessage } from "react-intl";
import { PrimaryButton } from "../../../../components";
import moment from "moment";
import { pathByRouteName } from "../../../../util/routes";

const MeetingReminder = props => {

    const {
        currentUser,
        routeConfiguration,
        history
    } = props;

    const upcomingMeeting = (getUpcomingMeeting(currentUser));
    if (!upcomingMeeting) return null;

    const {
        displayName,
        transactionId,
        videoMeetingDate,
        videoMeetingTime
    } = upcomingMeeting;

    const formattedDate = moment(
        `${videoMeetingDate.split('T')[0]} ${videoMeetingTime}`,
        "YYYY-MM-DD HH:mm"
    );
    const meetingDate = formattedDate?.format('ddd, D MMM [at] h:mm A');

    const now = moment().tz(formattedDate.tz());
    const minutesUntilMeeting = formattedDate.diff(now, 'minutes');

    if (minutesUntilMeeting < 0 || minutesUntilMeeting > 30) return null;

    return (
        <div className={css.root}>
            <FormattedMessage
                id="MeetingReminder.info"
                values={{ displayName, meetingDate }}
            />
            <PrimaryButton className={css.meetingButton}
                onClick={(e) => {
                    e.preventDefault();
                    const meetingPagePath = pathByRouteName('MeetingPage', routeConfiguration, {
                        id: transactionId,
                    });
                    history.push(meetingPagePath);
                }}
            >
                <FormattedMessage id="MeetingReminder.cta" />
            </PrimaryButton>
        </div>
    );
};

export default MeetingReminder;